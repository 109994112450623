import { RegulatedActivitiesData } from '../hooks/useRegulatedActivities'
import { markStringField } from './common'

export const searchRegulatedActivities = (
  data: RegulatedActivitiesData[],
  pattern: string,
  language = 'fr',
): RegulatedActivitiesData[] =>
  data
    // Filter data according to pattern and language
    .filter((d: any) => {
      let activityLabel = ''
      let activityDetail = ''

      try {
        //   Object.keys(d).forEach(key => {
        //     if (key === `activity_label_${language}`) {
        //       activityLabel = d[key]
        //     }
        //   })

        activityLabel = d[`activity_label_${language}`]
      } catch (e) {}

      try {
        activityDetail = d[`activity_detail_${language}`]
      } catch (e) {}

      return (
        activityLabel.toLocaleLowerCase().includes(pattern) ||
        activityDetail.toLocaleLowerCase().includes(pattern)
      )
    })
    // Mark found results to highlight them
    .map(item => ({
      id: item.id,
      activity_detail_mg: markStringField(
        item,
        pattern,
        'activity_detail',
        'mg',
        false,
      ),
      activity_detail_fr: markStringField(
        item,
        pattern,
        'activity_detail',
        'fr',
        false,
      ),
      activity_detail_en: markStringField(
        item,
        pattern,
        'activity_detail',
        'en',
        false,
      ),
      activity_label_mg: markStringField(item, pattern, 'activity_label', 'mg'),
      activity_label_fr: markStringField(item, pattern, 'activity_label', 'fr'),
      activity_label_en: markStringField(item, pattern, 'activity_label', 'en'),
    }))
