import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: `solid ${theme.palette.primary.main} 1px`,
      borderRadius: 16,
    },
    input: {
      marginLeft: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      flex: 1,
    },
  }),
)

interface SearchBarProps {
  placeholder?: string
  handleChange(value: string): void
}

const SearchBar: FC<SearchBarProps> = props => {
  const { placeholder } = props
  const classes = useStyles()

  const getNewValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.handleChange(event.target.value)
  }

  return (
    <Paper
      // component="form"
      className={classes.root}
      elevation={0}
    >
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={getNewValue}
      />
      <IconButton
        //type="submit"
        aria-label="search"
        size="small"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar
