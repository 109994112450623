import { graphql, useStaticQuery } from 'gatsby'

export interface RegulatedActivitiesData {
  id: string
  activity_label_mg: string
  activity_label_fr: string
  activity_label_en: string
  activity_detail_mg: string
  activity_detail_fr: string
  activity_detail_en: string
}

export default (): RegulatedActivitiesData[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiRegulatedActivities {
        nodes {
          id
          activity_label_mg
          activity_label_fr
          activity_label_en
          activity_detail_mg
          activity_detail_fr
          activity_detail_en
        }
      }
    }
  `)

  return data.allStrapiRegulatedActivities.nodes
}
