import Accordion, { AccordionProps } from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import { ComponentsProps } from '@material-ui/core/styles/props'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { FC } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { createMarkup } from '../../../utils/common'

const useStyles = makeStyles(theme => ({
  container: {},
  root: {
    boxShadow: 'none',
    // '& .MuiAccordionSummary-root.Mui-expanded': {
    //   minHeight: '0px',

    //   '& .MuiAccordionSummary-content.Mui-expanded': {
    //     margin: 0,
    //   },
    // },
    backgroundColor: 'transparent',
  },
  summary: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  heading: {
    fontWeight: 'normal',
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  circle: {
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    marginRight: theme.spacing(2),
    backgroundColor: 'black',
  },
  detailsContainer: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(12),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
}))

interface ICustomAccordionProps {
  identifier: string
  expand: boolean
  title: string
}

const CustomAccordion: FC<
  ICustomAccordionProps & ComponentsProps & AccordionProps
> = props => {
  const { identifier, expand, title, children } = props
  const classes = useStyles()

  const formattedTitle = createMarkup(title)

  return (
    <div className={classes.container}>
      <Accordion className={classes.root} expanded={expand} {...props}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${identifier}-content`}
          id={`${identifier}-header`}
          className={classes.summary}
        >
          <Box display="flex" alignItems="center">
            <Box>
              <Box className={classes.circle} />
            </Box>
            <Typography
              variant="h5"
              className={classes.heading}
              dangerouslySetInnerHTML={formattedTitle}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.detailsContainer}>
            <Typography variant="body2">{children}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default CustomAccordion
