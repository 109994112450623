import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomAccordion from '../components/common/customAccordion'
import TextMarkdown from '../components/customMarkdown/textMarkdown'
import SearchBar from '../components/forms/searchBar'
import SEO from '../components/seo'
import useRegulatedActivities from '../hooks/useRegulatedActivities'
import { RootState } from '../redux/store'
import { getInternationalizedValue } from '../utils/common'
import { searchRegulatedActivities } from '../utils/regulatedActivities'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  container: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
    padding: `0px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(3),

    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  titleContainer: {
    // paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const RegulatedActivities: FC = () => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<string | false>('')
  const timemoutRef = useRef<any>(0)

  const { language } = useSelector((state: RootState) => state.app)
  const data = useRegulatedActivities()
  const [activities, setActivities] = useState(data)

  const handleSearchChange = (pattern: string) => {
    if (timemoutRef.current) {
      clearTimeout(timemoutRef.current)
    }
    if (pattern) {
      timemoutRef.current = setTimeout(() => {
        const searchResults = searchRegulatedActivities(data, pattern, language)
        // console.log('!>>', searchResults)
        setActivities(searchResults)
      }, 1000)
    } else {
      setActivities(data)
    }
  }

  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Box className={classes.root}>
      <SEO title="Activities" />

      <Box className={classes.titleContainer}>
        <Typography variant="h4">LISTE DES ACTIVITÉS RÉGLEMENTÉES</Typography>
      </Box>
      <Box paddingY={4} className={classes.searchContainer}>
        <Box width="70%">
          <SearchBar
            placeholder="Utiliser un mot clé"
            handleChange={handleSearchChange}
          />
        </Box>
      </Box>
      <Box className={classes.container}>
        {activities.map(d => {
          const title = getInternationalizedValue(d, 'activity_label', language)
          const details = getInternationalizedValue(
            d,
            'activity_detail',
            language,
          )

          return (
            <CustomAccordion
              key={`regulated-activities-${d.id}`}
              title={title}
              identifier={d.id}
              expand={expanded === d.id}
              onChange={handleAccordionChange(d.id)}
            >
              <TextMarkdown text={details} allowDangerousHtml={true} />
            </CustomAccordion>
          )
        })}
      </Box>
    </Box>
  )
}

export default RegulatedActivities
